import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const { Types, Creators } = createActions({
  requestAdviser: ['data'],
  requestActiveAdviser: ['data'],
  requestAdviserBySede: ['data'],
  requestAdviserByIdBusiness: ['data'],
  requestAdviserByCategory: ['data'],
  receivedAdviser: ['data'],
  setAdviserSelect: ['data']
})

export const AdviserTypes = Types
export default Creators

export const INITIAL_STATE = Immutable({
  adviserList: [],
  adviserSelect: {}
})

export const requestAdviser = (state, { data }) => {
  return state
}

export const setAdviserSelect = (state, { data }) => {
  return Immutable.merge(state, {
    adviserSelect: data
  })
}

export const requestAdviserBySede = (state, { data }) => {
  return state
}

export const requestActiveAdviser = (state, { data }) => {
  return state
}

export const requestAdviserByIdBusiness = (state, { data }) => {
  return state
}

export const requestAdviserByCategory = (state, { data }) => {
  return state
}

export const receivedAdviser = (state, { data }) => {
  return Immutable.merge(state, {
    adviserList: data
  })
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.REQUEST_ADVISER]: requestAdviser,
  [Types.REQUEST_ACTIVE_ADVISER]: requestActiveAdviser,
  [Types.REQUEST_ADVISER_BY_CATEGORY]: requestAdviserByCategory,
  [Types.REQUEST_ADVISER_BY_SEDE]: requestAdviserBySede,
  [Types.REQUEST_ADVISER_BY_ID_BUSINESS]: requestAdviserByIdBusiness,
  [Types.RECEIVED_ADVISER]: receivedAdviser,
  [Types.SET_ADVISER_SELECT]: setAdviserSelect
})
