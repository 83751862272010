import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const { Types, Creators } = createActions({
  requestCategories: ['data'],
  receivedCategories: ['data'],
  requestCategoriesBusiness: ['data'],
  requestCategoriesByIdBusiness: ['data'],
  receivedCategoriesBusiness: ['data'],
  receivedCategorySelect: ['data']
})

export const CategoryTypes = Types
export default Creators

export const INITIAL_STATE = Immutable({
  categoryList: [],
  categoryListBusiness: [],
  categorySelect: null
})

export const requestCategories = (state, { data }) => {
  return state
}

export const requestCategoriesBusiness = (state, { data }) => {
  return state
}

export const receivedCategories = (state, { data }) => {
  return Immutable.merge(state, {
    categoryList: data
  })
}

export const requestCategoriesByIdBusiness = (state, { data }) => {
  return state
}

export const receivedCategoriesBusiness = (state, { data }) => {
  return Immutable.merge(state, {
    categoryListBusiness: data
  })
}

export const receivedCategorySelect = (state, { data }) => {
  return Immutable.merge(state, {
    categorySelect: data
  })
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.REQUEST_CATEGORIES]: requestCategories,
  [Types.RECEIVED_CATEGORIES]: receivedCategories,
  [Types.RECEIVED_CATEGORY_SELECT]: receivedCategorySelect,
  [Types.REQUEST_CATEGORIES_BUSINESS]: requestCategoriesBusiness,
  [Types.RECEIVED_CATEGORIES_BUSINESS]: receivedCategoriesBusiness,
  [Types.REQUEST_CATEGORIES_BY_ID_BUSINESS]: requestCategoriesByIdBusiness
})
