import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const { Types, Creators } = createActions({
  requestCall: ['data'],
  requestHangout: ['data'],
  setVideoCall: ['data']
})

export const VideoCallTypes = Types
export default Creators

export const INITIAL_STATE = Immutable({
  videoCall: {}
})

export const requestCall = (state, { data }) => {
  return state
}

export const requestHangout = (state, { data }) => {
  return state
}

export const setVideoCall = (state, { data }) => {
  return Immutable.merge(state, {
    videoCall: data
  })
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.REQUEST_CALL]: requestCall,
  [Types.REQUEST_HANGOUT]: requestHangout,
  [Types.SET_VIDEO_CALL]: setVideoCall
})
