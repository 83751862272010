import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const { Types, Creators } = createActions({
  actionContainerInfo: ['data'],
  setApiKey: ['data']
})

export const PanelTypes = Types
export default Creators

export const INITIAL_STATE = Immutable({
  isOpenContainer: false,
  apiKey: '',
  color: '#4c95e9',
  nameBusiness: 'iKow'
})

export const actionContainerInfo = (state, { data }) => {
  return Immutable.merge(state, {
    isOpenContainer: !data
  })
}

export const setApiKey = (state, { data }) => {
  return Immutable.merge(state, {
    apiKey: data.apiKey,
    color: data.color,
    nameBusiness: data.nameBusiness
  })
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ACTION_CONTAINER_INFO]: actionContainerInfo,
  [Types.SET_API_KEY]: setApiKey
})
