import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

const { Types, Creators } = createActions({
  requestMessage: ["data"],
  receiveMessage: ["data"],
  requestChats: ["data"],
  receiveChats: ["data"],
  saveMessage: ["data"],
  clearMessage: ["data"],
});

export const MessagesTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
  messageList: [],
  chatList: [],
});

export const clearMessage = (state, { data }) => {
  return INITIAL_STATE;
};

export const requestMessage = (state, { data }) => {
  return state;
};

export const saveMessage = (state, { data }) => {
  return state;
};

export const receiveMessage = (state, { data }) => {
  return Immutable.merge(state, {
    messageList: data,
  });
};

export const requestChats = (state, { data }) => {
  return state;
};

export const receiveChats = (state, { data }) => {
  return Immutable.merge(state, {
    chatList: data,
  });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.REQUEST_MESSAGE]: requestMessage,
  [Types.SAVE_MESSAGE]: saveMessage,
  [Types.RECEIVE_MESSAGE]: receiveMessage,
  [Types.RECEIVE_CHATS]: receiveChats,
  [Types.REQUEST_CHATS]: requestChats,
});
