import Reactotron from 'reactotron-react-js'
import { reactotronRedux } from 'reactotron-redux'
import sagaPlugin from 'reactotron-redux-saga'

const reactotron = Reactotron.configure({ name: 'React Config' })
  .use(reactotronRedux())
  .use(sagaPlugin()) //  <- here i am!
  .connect() //Don't forget about me!

export default reactotron

// Reactotron.configure() // we can use plugins here -- more on this later
//   .connect(); // let's connect!
