import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const { Types, Creators } = createActions({
  requestUser: ['data'],
  setUser: ['data'],
  loginWithRedes: ['data'],
  logOut: ['data']
})

export const UserTypes = Types
export default Creators

export const INITIAL_STATE = Immutable({
  user: {}
})

export const requestUser = (state, { data }) => {
  return state
}

export const loginWithRedes = (state, { data }) => {
  return state
}

export const logOut = (state, { data }) => {
  return INITIAL_STATE
}

export const setUser = (state, { data }) => {
  return Immutable.merge(state, {
    user: data
  })
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.REQUEST_USER]: requestUser,
  [Types.SET_USER]: setUser,
  [Types.LOGIN_WITH_REDES]: loginWithRedes,
  [Types.LOG_OUT]: logOut
})
