import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const { Types, Creators } = createActions({
  requestOffices: ['data'],
  requestCountry: ['data'],
  requestState: ['data'],
  requestCities: ['data'],
  requestLatitude: ['data'],
  receivedCountry: ['data'],
  receivedState: ['data'],
  receivedCities: ['data'],
  receivedCitiesBusiness: ['data'],
  receivedOffices: ['data'],
  setOfficeSelect: ['data']
})

export const LocationTypes = Types
export default Creators

export const INITIAL_STATE = Immutable({
  lat: '',
  lon: '',
  countries: [],
  statesCountry: [],
  cities: [],
  citiesBusiness: [],
  offices: [],
  officeSelect: {}
})

export const requestOffices = (state, { data }) => {
  return state
}

export const requestCountry = (state, { data }) => {
  return state
}

export const requestState = (state, { data }) => {
  return state
}

export const requestCities = (state, { data }) => {
  return state
}

export const receivedCountry = (state, { data }) => {
  return Immutable.merge(state, {
    countries: data
  })
}

export const receivedState = (state, { data }) => {
  return Immutable.merge(state, {
    statesCountry: data
  })
}

export const receivedCities = (state, { data }) => {
  return Immutable.merge(state, {
    cities: data
  })
}

export const receivedCitiesBusiness = (state, { data }) => {
  return Immutable.merge(state, {
    citiesBusiness: data
  })
}

export const receivedOffices = (state, { data }) => {
  return Immutable.merge(state, {
    offices: data
  })
}

export const setOfficeSelect = (state, { data }) => {
  return Immutable.merge(state, {
    officeSelect: data
  })
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.REQUEST_OFFICES]: requestOffices,
  [Types.REQUEST_COUNTRY]: requestCountry,
  [Types.REQUEST_STATE]: requestState,
  [Types.REQUEST_CITIES]: requestCities,
  [Types.RECEIVED_COUNTRY]: receivedCountry,
  [Types.RECEIVED_STATE]: receivedState,
  [Types.RECEIVED_CITIES]: receivedCities,
  [Types.RECEIVED_CITIES_BUSINESS]: receivedCitiesBusiness,
  [Types.RECEIVED_OFFICES]: receivedOffices,
  [Types.SET_OFFICE_SELECT]: setOfficeSelect
})
